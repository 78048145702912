import { state } from '../state/modules/authfack'
import { baseRequest, makeRequestOptions, url} from './utils'

export const userService = {
    login,
    logout,
    register,
    getAll,
    getUsersActivities,
    updateUser,
    updatePassword,
    getAllDeviceRegistrations,
    removeToken,
    removeAllTokens,
    updateProfileImage,
    removeProfileImage,
    deleteAccount,
    enableTwoFactorAuth,
    makeAuthentication,
    userDetails,
    resendVerificationCode
}

async function login(email, password) {
    return makeRequestOptions('POST', '/login', { email, password })
        .then(response => {
            if (response.token) {
                localStorage.setItem('token', JSON.stringify(response.token))
                localStorage.setItem('user_uuid', JSON.stringify(response.user_uuid))
            }
            return response
        })
}

async function makeAuthentication(user_uuid, code, type) {
    const types = {
        '2fa': 'verify_otp_code',
        'secondary': 'verify_secondary_verification'
    }
    
    return makeRequestOptions('POST', `/${types[type]}`, { user_uuid, code })
        .then(token => {
            if (token.token) {
                localStorage.setItem('token', JSON.stringify(token.token))
            }
            return token
        })
}

async function resendVerificationCode(user_uuid) {
    return makeRequestOptions('POST', '/resend_code_verification', { user_uuid })
}

async function logout() {
    const session_token = state?.user?.token
    localStorage.removeItem('user')
    localStorage.removeItem('token')
    localStorage.removeItem('user_uuid')
    return makeRequestOptions('POST', '/logout', { session_token })
}

async function register(user) {
    return makeRequestOptions('POST', '/user_create', user)
}

async function getAll() {
    return makeRequestOptions('GET', '/users')
}

async function getUsersActivities() {
    const date = new Date()
    return [
        {
            url_thumbnail: 'https://fastly.picsum.photos/id/450/200/200.jpg?hmac=DluUYibC-zBoNHLOHsO6aHIuiA3pDhholFjiR5KcwR0',
            name: 'User 1',
            action: 'created a new task',
            created_at: date,
            description: 'Task #123'
        },
        {
            url_thumbnail: '',
            name: 'User 2',
            action: 'completed a task',
            created_at: date,
            description: 'Task #123'
        },
        {
            url_thumbnail: 'https://fastly.picsum.photos/id/223/200/200.jpg?hmac=CNNyWbBcEAJ7TPkTmEEwdGrLFEYkxpTeVwJ7U0LB30Y',
            name: 'User 3',
            action: 'created a new task',
            created_at: date,
            description: 'Task #123'
        },
        {
            url_thumbnail: 'https://fastly.picsum.photos/id/450/200/200.jpg?hmac=DluUYibC-zBoNHLOHsO6aHIuiA3pDhholFjiR5KcwR0',
            name: 'User 4',
            action: 'completed a task',
            created_at: date,
            description: 'Task #123'
        }
    ]
}

async function updateUser(data) {
    return makeRequestOptions('PUT', `/update_user`, data)
}

async function updatePassword(user_uuid, data) {
    return makeRequestOptions('PUT', `/update_password/${user_uuid}`, data)
}

async function getAllDeviceRegistrations() {
    return makeRequestOptions('GET', `/device_registrations`)
}

async function removeToken(token_uuid) {
    return makeRequestOptions('DELETE', `/auth?token_uuid=${token_uuid}`)
}

async function removeAllTokens() {
    return makeRequestOptions('DELETE', `/auth`)
}

async function updateProfileImage(formData) {
    return makeRequestOptions('POST', `/upload_profile_picture`, formData, { formData: true })
}

async function removeProfileImage(user_uuid) {
    return makeRequestOptions('DELETE', `/delete_profile_picture/${user_uuid}`)
}

async function deleteAccount(user_uuid, password) {
    return makeRequestOptions('DELETE', `/delete_user/${user_uuid}`, { password })
}

async function enableTwoFactorAuth(user_uuid) {
    const requestOptions = {
        ...baseRequest,
        method: 'POST',
        body: JSON.stringify({ user_uuid })
    }
    return fetch(`${url}/generate_otp_secret`, requestOptions).then(img => img.blob()).then(blob => URL.createObjectURL(blob))
}

async function userDetails(user_uuid=null) {
    if (user_uuid) {
        return makeRequestOptions('GET', `/user_details?user_uuid=${user_uuid}`)
    }
    return makeRequestOptions('GET', `/user_details`)
}
