import { v4 as uuidv4 } from 'uuid'
import { notificationsService } from '../../helpers/notifications.service'
import { state as stateUser } from './authfack'

export const state = {
    uuid: null,
    type: null,
    message: null,
    notifications: []
}

export const actions = {
    success({ commit }, message) {
        commit('success', message)
    },
    error({ commit }, message) {
        commit('error', message)
    },
    info({ commit }, message) {
        commit('info', message)
    },
    clear({ commit }) {
        commit('clear')
    },
    async getNotifications({ commit, dispatch }) {
        try {
            if (!stateUser.user?.uuid) {
                return
            }
            const res = await notificationsService.getAll()
            commit('setNotifications', res.data?.sort((a, b) => new Date(b.created_at) - new Date(a.created_at)))
        } catch (error) {
            dispatch('notification/error', 'There was an error loading notifications', { root: true })
            console.log(error)
        }
    },
    async readNotification({ commit, dispatch }, notification_uuid) {
        try {
            await notificationsService.readNotification(notification_uuid)
            const notificationsFiltered = state.notifications.filter(notification => notification.uuid !== notification_uuid)
            commit('setNotifications', notificationsFiltered)
        } catch (error) {
            dispatch('notification/success', 'There was an error reading the notification', { root: true })
        }
    },
    pushNotification({ commit }, notification) {
        commit('setNotifications', [...state.notifications, notification])
    }
}

export const mutations = {
    success(state, message) {
        state.uuid = uuidv4()
        state.type = 'success'
        state.message = message
    },
    error(state, message) {
        state.uuid = uuidv4()
        state.type = 'error'
        state.message = message
    },
    info(state, message) {
        state.uuid = uuidv4()
        state.type = 'info'
        state.message = message
    },
    clear(state) {
        state.uuid = uuidv4()
        state.type = null
        state.message = null
    },
    setNotifications(state, notifications) {
        state.notifications = notifications
    }
}

export const getters = {
    uuid: state => state.uuid,
    type: state => state.type,
    message: state => state.message,
    notifications: state => state.notifications
}
