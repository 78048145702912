import { makeRequestOptions } from './utils'

export const notificationsService = {
  getAll,
  readNotification,
}

async function getAll() {
  return makeRequestOptions('GET', `/notifications`)
}

async function readNotification(notification_uuid) {
  return makeRequestOptions('PATCH', `/notifications/read/${notification_uuid}`)
}
