import store from "@/state/store"

export default [
  {
    path: "/login",
    name: "login",
    component: () => import("../views/account/login.vue"),
    meta: {
      title: "Login",
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters["authfack/loggedIn"]) {
          // Redirect to the home page instead
          next({ name: "default" })
          next()
        } else {
          // Continue to the login page
          next()
        }
      },
    },
  },
  {
    path: "/authentication",
    name: "Authentication",
    component: () => import("../views/account/2fa.vue"),
    meta: {
      title: "Confirm Authentication",
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters["authfack/loggedIn"]) {
          // Redirect to the home page instead
          next({ name: "default" })
        } else {
          // Continue to the login page
          next()
        }
      },
    },
  },
  {
    path: "/register",
    name: "Register",
    component: () => import("../views/account/register.vue"),
    meta: {
      title: "Register",
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters["authfack/loggedIn"]) {
          // Redirect to the home page instead
          next({ name: "default" })
        } else {
          // Continue to the login page
          next()
        }
      },
    },
  },
  {
    path: "/forgot-password",
    name: "Forgot password",
    component: () => import("../views/account/forgot-password.vue"),
    meta: {
      title: "Forgot Password",
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters["authfack/loggedIn"]) {
          // Redirect to the home page instead
          next({ name: "default" })
        } else {
          // Continue to the login page
          next()
        }
      },
    },
  },
  {

    path: "/",
    name: "default",
    meta: {
      title: "Dashboard",
      authRequired: true,
    },
    component: () => import("../views/dashboard/analytics/index"),
  },
  {
    path: "/financial-analisys",
    name: "financial-analisys",
    meta: {
      title: "Financial Analisys",
      authRequired: true,
    },
    component: () => import("../views/pages/coming-soon"),
  },
  {
    path: "/product-dashboard",
    name: "product-dashboard",
    meta: {
      title: "Product Dashboard",
      authRequired: true,
    },
    component: () => import("../views/pages/coming-soon"),
  },
  {
    path: "/dashboard/data-lake",
    name: "dashboard-data-lake",
    meta: {
      title: "Data Lake",
      authRequired: true,
    },
    component: () => import("../views/dashboard/datalake/index"),
  },
  {
    path: "/dashboard/product",
    name: "dashboard-product",
    meta: {
      title: "Product Dashboard",
      authRequired: true,
    },
    component: () => import("../views/dashboard/analytics_products/index"),
  },
  {
    path: "/business/profile",
    name: "business-profile",
    meta: {
      title: "Business Profile", authRequired: true,
    },
    component: () => import("../views/business/profile"),
  },
  {
    path: "/business/team",
    name: "business-team",
    meta: {
      title: "Business Team", authRequired: true,
    },
    component: () => import("../views/business/team"),
  },
  {
    path: "/business/team/profile/:id",
    name: "business-team-profile",
    meta: {
      title: "Business Team Profile", authRequired: true,
    },
    component: () => import("../views/business/team/profile.vue"),
  },
  {
    path: "/business/invite-to-team",
    name: "invite-to-team",
    meta: {
      title: "Invite to Team", authRequired: false,
    },
    component: () => import("../views/business/invite-to-team"),
  },
  {
    path: "/business/branches",
    name: "business-branches",
    meta: {
      title: "Branches",
      authRequired: true,
    },
    component: () => import("../views/business/branch/index.vue"),
  },
  {
    path: "/system/settings",
    name: "system-settings",
    meta: {
      title: "System Settings", authRequired: true,
    },
    component: () => import("../views/system/settings"),
  },
  {
    path: "/pages/profile",
    name: "profile",
    meta: { title: "Profile", authRequired: true },
    component: () => import("../views/pages/profile/simple"),
  },
  {
    path: "/pages/profile-setting",
    name: "profile-setting",
    meta: { title: "Setting", authRequired: true },
    component: () => import("../views/pages/profile/setting"),
  },
  {
    path: "/pages/maintenance",
    name: "maintenance",
    meta: { title: "Maintanance", authRequired: true },
    component: () => import("../views/pages/maintenance"),
  },
  {
    path: "/pages/coming-soon",
    name: "coming-soon",
    meta: { title: "Comming Soon", authRequired: true },
    component: () => import("../views/pages/coming-soon"),
  },
  {
    path: "/pages/privacy-policy",
    name: "privacy-policy",
    meta: { title: "Privacy Policy", authRequired: true },
    component: () => import("../views/pages/privacy-policy"),
  },
  {
    path: "/pages/term-conditions",
    name: "term-conditions",
    meta: { title: "Term Conditions", authRequired: true },
    component: () => import("../views/pages/term-conditions"),
  },
  {
    path: "/chat",
    name: "chat",
    meta: { title: "Chat", authRequired: true },
    component: () => import("../views/apps/chat"),
  },
  {
    path: "/apps/data-manager",
    name: "apps-data-manager",
    meta: { title: "Data Manager", authRequired: true },
    component: () => import("../views/apps/filemanager"),
  },
  {
    path: "/apps/upload-file",
    name: "apps-upload-file",
    meta: { title: "Upload File", authRequired: true },
    component: () => import("../views/apps/upload-file"),
  },
  {
    path: "/apps/upgrade-plan",
    name: "apps-upgrade-plan",
    meta: {
      title: "Upgrade Plan",
      authRequired: true,
    },
    component: () => import("../views/pages/coming-soon"),
  },
  {
    path: "/dev/data-mapping",
    name: "dev-data-mapping",
    meta: {
      title: "Data Mapping",
      authRequired: true,
    },
    component: () => import("../views/dev/data-mapping.vue"),
  },
  {
    path: "/dev/system-modules",
    name: "dev-system-modules",
    meta: {
      title: "System Modules",
      authRequired: true,
    },
    component: () => import("../views/dev/system-modules.vue"),
  },

  /* STOCK */
  {
    path: "/stock/products",
    name: "stock-products",
    meta: {
      title: "Products",
      authRequired: true,
    },
    component: () => import("../views/stock/Product"),
  },

  {
    path: "/stock/aging",
    name: "stock-aging",
    meta: {
      title: "Aging",
      authRequired: true,
    },
    component: () => import("../views/stock/aging"),
  },

  {
    path: "/stock/inventory-history",
    name: "stock-inventory-history",
    meta: {
      title: "Inventory History",
      authRequired: true,
    },
    component: () => import("../views/stock/InventoryHistory"),
  },

  {
    path: "/stock/inventory-situation",
    name: "stock-inventory-situation",
    meta: {
      title: "Inventory Situation",
      authRequired: true,
    },
    component: () => import("../views/stock/InventorySituation"),
  },

  {
    path: "/stock/demand-pattern",
    name: "stock-demand-pattern",
    meta: {
      title: "Demand Pattern",
      authRequired: true,
    },
    component: () => import("../views/stock/DemandPattern"),
  },

  {
    path: "/stock/products-analysis",
    name: "stock-products-analysis",
    meta: {
      title: "Products Analysis",
      authRequired: true,
    },
    component: () => import("../views/stock/ProductsAnalysis"),
  },

  /* SALES */

  {
    path: "/sales/forecasting",
    name: "sales-forecasting",
    meta: {
      title: "Forecasting",
      authRequired: true,
    },
    component: () => import("../views/sales/forecasting"),
  },
  {
    path: "/sales/price-sensitive",
    name: "sales-price-sensitive",
    meta: {
      title: "Sales Price Sensitive",
      authRequired: true,
    },
    component: () => import("../views/sales/price-sensitive"),
  },
  {
    path: "/sales/category-cross-selling",
    name: "sales-category-cross-selling",
    meta: {
      title: "Sales Category Cross Selling",
      authRequired: true,
    },
    component: () => import("../views/sales/CategoriesCrossSelling"),
  },
  {
    path: "/sales/sales-history",
    name: "sales-sales-history",
    meta: {
      title: "Sales History",
      authRequired: true,
    },
    component: () => import("../views/sales/SalesHistory"),
  },

  /* PURCHASES */

  {
    path: "/input/purchase-config",
    name: "input-purchase-config",
    meta: {
      title: "Purchase Config",
      authRequired: true,
    },
    component: () => import("../views/inputs/PurchaseConfig"),
  },
  {
    path: "/input/purchase-analysis",
    name: "input-purchase-analysis",
    meta: {
      title: "Purchase Analysis",
      authRequired: true,
    },
    component: () => import("../views/inputs/PurchaseAnalysis"),
  },
  {
    path: "/input/purchase-forecasting-by-supplier",
    name: "input-purchase-forecasting-by-supplier",
    meta: {
      title: "Purchase Analysis",
      authRequired: true,
    },
    component: () => import("../views/inputs/PurchaseForecastingBySupplier"),
  },

  /* SUPPLIERS */

  {
    path: "/suppliers/suppliers",
    name: "suppliers-suppliers",
    meta: {
      title: "Suppliers",
      authRequired: true,
    },
    component: () => import("../views/suppliers/Suppliers"),
  },

  {
    path: "/suppliers/products",
    name: "suppliers-products",
    meta: {
      title: "Suppliers Products",
      authRequired: true,
    },
    component: () => import("../views/suppliers/Products"),
  },

  {
    path: "/suppliers/products-suppliers",
    name: "suppliers-products-suppliers",
    meta: {
      title: "Products Suppliers",
      authRequired: true,
    },
    component: () => import("../views/suppliers/ProductsSuppliers"),
  },

  /* CLIENTS */

  {
    path: "/customers/customers-analisys",
    name: "customer-analisys",
    meta: {
      title: "Customer Analisys",
      authRequired: true,
    },
    component: () => import("../views/customers/CustomerAnalisys"),
  },
  {
    path: "/customers/customer-sales_history",
    name: "customer-sales_history",
    meta: {
      title: "Customer Sales History",
      authRequired: true,
    },
    component: () => import("../views/customers/CustomerSalesHistory"),
  },
  {
    path: "/customers/customers",
    name: "customers-customers",
    meta: {
      title: "Customer",
      authRequired: true,
    },
    component: () => import("../views/customers/Customers"),
  },

  /* OPERATION */

  {
    path: "/operation/sale-indication",
    name: "operation-sale-indication",
    meta: {
      title: "Sale Indication",
      authRequired: true,
    },
    component: () => import("../views/operation/SaleIndication.vue"),
  },

  {
    path: "/operation/prescriptions",
    name: "operation-prescriptions",
    meta: {
      title: "Prescriptions",
      authRequired: true,
    },
    component: () => import("../views/operation/Prescriptions.vue"),
  },

]