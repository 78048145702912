import { userService } from '../../helpers/user.service'
import { userPreferencesService } from '../../helpers/user-preferences.service'
import router from '../../router/index'
import i18n from '../../i18n'

export const user = JSON.parse(localStorage.getItem('user'))
export const state = user
    ? { status: { loggeduser: true }, user, devicesRegistrations: [] }
    : { status: { thereUserUUID: false }, user: null, devicesRegistrations: [] }

// dispatch
export const actions = {
    // Logs in the user.
    // eslint-disable-next-line no-unused-vars
    login({ dispatch, commit }, { email, password }) {
        commit('loginRequest', { email })

        userService.login(email, password)
            .then(
                res => {
                    if (res?.thereUserUUID) {
                        commit('authentication', res)
                        router.push('/authentication?type=2fa&email=' + email)
                    } else if (res?.secondary_verification) {
                        commit('authentication', res)
                        router.push('/authentication?type=secondary&email=' + email)
                    } else {
                        commit('loginSuccess', res.token)
                        dispatch('notification/success', 'Login successful', { root: true })
                        dispatch('authfack/fetchUserDetails', res.user_uuid, { root: true })
                            .then(() => {
                                router.push('/')
                            })
                    }
                },
                error => {
                    commit('loginFailure', error)
                    dispatch('notification/error', error || 'Login failed', { root: true })
                }
            )
    },
    makeAuthentication({ dispatch, commit }, [code, type]) {
        if (!state.user_uuid) {
            dispatch('notification/error', 'Invalid request', { root: true })
            router.push('/login')
            return
        }
        userService.makeAuthentication(state.user_uuid, code, type)
            .then(
                res => {
                    commit('loginSuccess', res.token)
                    dispatch('notification/success', 'Login successful', { root: true })
                    dispatch('authfack/fetchUserDetails', res.user_uuid, { root: true })
                        .then(() => {
                            router.push('/')
                        })
                },
                error => {
                    commit('loginFailure', error)
                    dispatch('notification/error', error, { root: true })
                }
            )
    },
    resendVerificationCode({ dispatch }) {
        if (!state.user_uuid) {
            dispatch('notification/error', 'Invalid request', { root: true })
            router.push('/login')
            return
        }
        userService.resendVerificationCode(state.user_uuid)
            .then(
                () => {
                    dispatch('notification/success', 'Verification code sent', { root: true })
                },
                error => {
                    dispatch('notification/error', error, { root: true })
                }
            )
    },
    async logout() {
        try {
            await userService.logout()
            // eslint-disable-next-line
        } catch (error) { }
        state.status = {}
        state.user = null
        localStorage.removeItem('user')
        localStorage.removeItem('token')
        window.location.reload()
    },
    // register the user
    async registeruser({ dispatch, commit }, user) {
        try {
            commit('registerRequest', user)
            await userService.register(user)
            commit('registerSuccess', user)
            dispatch('notification/success', 'Registration successful', { root: true })
            router.push('/login')
        } catch (error) {
            commit('registerFailure', error)
            dispatch('notification/error', error, { root: true })
        }
    },
    async updateUser({ dispatch, commit }, [user]) {
        try {
            await userService.updateUser(user)
            commit('updateUserRequest', { ...user })
        } catch (error) {
            commit('registerFailure', error)
            dispatch('notification/error', error, { root: true })
        }
    },
    updatePassword({ dispatch }, [user_uuid, { old_password, new_password }]) {
        userService.updatePassword(user_uuid, { old_password, new_password })
            .then(
                () => {
                    dispatch('notification/success', 'Password updated successfully', { root: true })
                    router.push('/logout')
                },
                error => {
                    dispatch('notification/error', error, { root: true })
                }
            )
    },
    removeToken({ dispatch }, token_uuid) {
        const apenasUm = state.devicesRegistrations.length === 1
        if (apenasUm) {
            dispatch('notification/success', 'All will be removed, please sign in again', { root: true })
            router.push('/logout')
        }
        else {
            userService.removeToken(token_uuid)
                .then(
                    () => {
                        dispatch('notification/success', 'Device removed successfully', { root: true })
                        dispatch('authfack/getAllDeviceRegistrations', { root: true })
                    }
                )
                .catch(error => {
                    dispatch('notification/error', error, { root: true })
                })
        }
    },
    async getAllDeviceRegistrations({ commit, dispatch }) {
        userService.getAllDeviceRegistrations()
            .then(
                devicesRegistrations => {
                    commit('getAllDeviceRegistrations', devicesRegistrations.device_registrations)
                    if (devicesRegistrations.device_registrations.length === 0) {
                        dispatch('notification/info', 'No devices registered', { root: true })
                    }
                }
            ).catch(() => {
                dispatch('notification/error', 'Error fetching devices', { root: true })
                commit('getAllDeviceRegistrations', [])
            })
    },
    removeAllTokens({ dispatch }) {
        userService.removeAllTokens()
            .then(
                () => {
                    dispatch('notification/success', 'All sessions removed successfully, please login again', { root: true })
                    router.push('/logout')
                },
                error => {
                    dispatch('notification/error', error, { root: true })
                }
            )
    },
    updateProfileImage({ dispatch, commit }, [formData]) {
        userService.updateProfileImage(formData)
            .then(
                user => {
                    commit('updateUserRequest', { url_thumbnail: user.url_thumbnail })
                    dispatch('notification/success', 'Profile image updated successfully', { root: true })
                    window.location.reload()
                },
                error => {
                    dispatch('notification/error', error, { root: true })
                }
            )
    },
    removeProfileImage({ dispatch, commit }, user_uuid) {
        userService.removeProfileImage(user_uuid)
            .then(
                () => {
                    commit('updateUserRequest', { url_thumbnail: null })
                    dispatch('notification/success', 'Profile image removed successfully', { root: true })
                    window.location.reload()
                },
                error => {
                    dispatch('notification/error', error, { root: true })
                }
            )
    },
    deleteAccount({ dispatch }, [user_uuid, password]) {
        userService.deleteAccount(user_uuid, password)
            .then(
                () => {
                    dispatch('notification/success', 'Account deleted successfully', { root: true })
                    router.push('/logout')
                },
                error => {
                    dispatch('notification/error', error, { root: true })
                }
            )
    },
    async updatePreference({ dispatch, commit }, body) {
        userPreferencesService.update(body)
            .then(
                (res) => {
                    dispatch('notification/success', 'Preference updated', { root: true })
                    commit('updatePreference', res.preferences)
                    if (body?.language_code) {
                        i18n.global.locale = body.language_code
                    }
                },
                error => {
                    dispatch('notification/error', error, { root: true })
                }
            )

    },
    async fetchUserDetails({ dispatch, commit }, user_uuid) {
        try {
            const res = await userService.userDetails(user_uuid)
            localStorage.setItem('user', JSON.stringify(res.data))
            commit('updateUserRequest', res.data)
        } catch (error) {
            dispatch('notification/error', error, { root: true })
        }

    }
}

// commit
export const mutations = {
    loginRequest(state, user) {
        state.status = { loggingIn: true }
        state.user = user
    },
    loginSuccess(state, token) {
        state.status = { loggeduser: true, thereUserUUID: false }
        state.user = { ...state.user, token }
    },
    loginFailure(state) {
        state.status = {}
        state.user = null
    },
    registerRequest(state) {
        state.status = { registering: true }
    },
    registerSuccess(state) {
        state.status = {}
    },
    registerFailure(state) {
        state.status = {}
    },
    updateUserRequest(state, user) {
        state.status = { updating: true }
        state.user = { ...state.user, ...user }
        i18n.global.locale = user.preferences.language_code
        localStorage.setItem('user', JSON.stringify(state.user))
    },
    getAllDeviceRegistrations(state, devicesRegistrations) {
        state.devicesRegistrations = devicesRegistrations
    },
    updatePreference(state, preferences) {
        state.user.preferences = preferences
        localStorage.setItem('user', JSON.stringify(state.user))
    },
    authentication(state, res) {
        state.status = { thereUserUUID: true }
        state.user_uuid = res.user_uuid
    },
    updateUserCustomer(state, customer) {
        state.user = { ...state.user, customer }
        localStorage.setItem('user', JSON.stringify(state.user))
    }
}

export const getters = {
    isLoggedIn: state => !!state.user,
    haveDevicesRegistrations: state => state.devicesRegistrations?.length > 0,
    thereUserUUID: state => !!state.status.thereUserUUID
}
